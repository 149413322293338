import moment from 'moment';
import '../../../css/onboarding-details-tab.css';
import { useEffect } from 'react';
import { useState } from 'react';
import { dateFormat } from '../../../helpers/common/common.function';

function KycTab({ onboardingInfo }) {
    const [kycDetails, setKycDetails] = useState(null);
    const [parentDetails, setParentDetails] = useState(null);
    const [residentType,setResidentType] = useState("")
    const [nationality , setNatonality] = useState("")

    useEffect(() => {
        if (onboardingInfo?.kyc_details && onboardingInfo?.kyc_details?.kyc_details) {
            setKycDetails(onboardingInfo?.kyc_details?.kyc_details);
        }

        if (onboardingInfo?.kyc_details && onboardingInfo?.kyc_details?.parent_details) {
            setParentDetails(onboardingInfo?.kyc_details?.parent_details);
        }
        setResidentType(onboardingInfo?.resident_type ? onboardingInfo.resident_type : "")
        setNatonality(onboardingInfo?.nationality ? onboardingInfo.nationality : "")
    }, [onboardingInfo]);

    return (
        <>
            <div className='exit_tab_box kyc_box'>
                <div className='row'>
                    <div className='col-md-6 zindex9'>
                        <div className="table_main_tit pb-20"><h2>Resident Details</h2></div>
                        <div className='brd_box_card'>
                            <div className="box_head">
                                <h2> <img src="/images/building.svg" className="pr-2" alt="" /> RESIDENT</h2>
                            </div>
                            <div className="box_contain details_tab">
                                <div className='kyc_sub'>
                                    {
                                        nationality.toLowerCase()  == "indian" ?
                                        <>
                                            <div className="d-flex justify-content-between box_card_data">
                                                <h3>Is Adhar Verified</h3>
                                                <i>:</i>
                                                {
                                                    kycDetails?.is_aadhaar_verified ? (
                                                        <span className='green_verify'>
                                                            <img src="/images/check-verified.svg" className="pr-1" alt="" />{' '}Verified
                                                        </span>
                                                    ) : (
                                                        <span className='red_not_verify'>
                                                            <img src="/images/red_close.svg" className="pr-1" alt="" />{' '}Not Verified
                                                        </span>
                                                    )
                                                }
                                            </div>
                                            <div className="d-flex justify-content-between box_card_data">
                                                <h3>Adhar number</h3>
                                                <i>:</i>
                                                <span>{kycDetails?.aadhaar_no ? kycDetails?.aadhaar_no : '--'}</span>
                                            </div>
                                            <div className="d-flex justify-content-between box_card_data">
                                                <h3>Adhar document</h3>
                                                <i>:</i>
                                                {
                                                    kycDetails?.aadhar_document_url ? (
                                                        <span className='pdf_clr cursor-pointor'>
                                                            <a
                                                                href={kycDetails?.aadhar_document_url}
                                                                download
                                                                target='_blank'
                                                            ><img src="/images/book.svg" className="pr-1" alt="Book" />{' '}ID-Card.pdf</a>
                                                        </span>
                                                    ) : (
                                                        <span className='pdf_clr'>--</span>
                                                    )
                                                }
                                            </div>
                                            <div className="d-flex justify-content-between box_card_data">
                                                <h3>Date of birth</h3>
                                                <i>:</i>
                                                {/* {console.log("kycDetails?.dob -- ",kycDetails?.dob)} */}
                                                <span>{kycDetails?.dob ? kycDetails?.dob : '--'}</span>
                                                {/* <span>{kycDetails?.dob ? moment(new Date(kycDetails?.dob)).format('DD MMM YYYY') : '--'}</span> */}
                                            </div>
                                            <div className="d-flex justify-content-between box_card_data">
                                                <h3>Name as per Adhar</h3>
                                                <i>:</i>
                                                <span>{kycDetails?.name_as_per_aadhar ? kycDetails?.name_as_per_aadhar : '--'}</span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="d-flex justify-content-between box_card_data">
                                                <h3>Is Passport Verified</h3>
                                                <i>:</i>
                                                {
                                                    kycDetails?.is_passport_verified ? (
                                                        <span className='green_verify'>
                                                            <img src="/images/check-verified.svg" className="pr-1" alt="" />{' '}Verified
                                                        </span>
                                                    ) : (
                                                        <span className='red_not_verify'>
                                                            <img src="/images/red_close.svg" className="pr-1" alt="" />{' '}Not Verified
                                                        </span>
                                                    )
                                                }
                                            </div>
                                            <div className="d-flex justify-content-between box_card_data">
                                                <h3>Passport number</h3>
                                                <i>:</i>
                                                <span>{kycDetails?.passport_no ? kycDetails?.passport_no : '--'}</span>
                                            </div>
                                            <div className="d-flex justify-content-between box_card_data">
                                                <h3>Passport front page</h3>
                                                <i>:</i>
                                                {
                                                    kycDetails?.passport_front_page_url ? (
                                                        <span className='pdf_clr cursor-pointor'>
                                                            <a
                                                                href={kycDetails?.passport_front_page_url}
                                                                download
                                                                target='_blank'
                                                            ><img src="/images/book.svg" className="pr-1" alt="Book" />{' '}Passport-front.pdf</a>
                                                        </span>
                                                    ) : (
                                                        <span className='pdf_clr'>--</span>
                                                    )
                                                }
                                            </div>
                                            <div className="d-flex justify-content-between box_card_data">
                                                <h3>Passport back page</h3>
                                                <i>:</i>
                                                {
                                                    kycDetails?.passport_back_page_url ? (
                                                        <span className='pdf_clr cursor-pointor'>
                                                            <a
                                                                href={kycDetails?.passport_back_page_url}
                                                                download
                                                                target='_blank'
                                                            ><img src="/images/book.svg" className="pr-1" alt="Book" />{' '}Passport-back.pdf</a>
                                                        </span>
                                                    ) : (
                                                        <span className='pdf_clr'>--</span>
                                                    )
                                                }
                                            </div>
                                            <div className="d-flex justify-content-between box_card_data">
                                                <h3>Verifed Entity</h3>
                                                <i>:</i>
                                                <span>{kycDetails?.verified_entity ? kycDetails?.verified_entity : '--'}</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='kyc_sub'>
                                    <h2>RESIDENTIAL ADDRESS</h2>
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>Address </h3>
                                        <i>:</i>
                                        <span className='address_details'>{(kycDetails?.address?.permanent_address_details !== undefined) ? kycDetails?.address?.permanent_address_details?.address : '--'}</span>
                                    </div>
                                    {/* <div className="d-flex justify-content-between box_card_data">
                                        <h3>Address 2</h3>
                                        <i>:</i>
                                        <span>--</span>
                                    </div> */}
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>City</h3>
                                        <i>:</i>
                                        <span>{(kycDetails?.address?.permanent_address_details?.district_or_city) ? kycDetails?.address?.permanent_address_details?.district_or_city : '--'}</span>
                                    </div>
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>State</h3>
                                        <i>:</i>
                                        <span>{(kycDetails?.address?.permanent_address_details?.state) ? kycDetails?.address?.permanent_address_details?.state : '--'}</span>
                                    </div>
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>Pincode</h3>
                                        <i>:</i>
                                        <span>{(kycDetails?.address?.permanent_address_details?.pincode) ? kycDetails?.address?.permanent_address_details?.pincode : '--'}</span>
                                    </div>
                                    {/* <div className="d-flex justify-content-between box_card_data">
                                        <h3>Country</h3>
                                        <i>:</i>
                                        <span>N/A</span>
                                    </div> */}
                                </div>
                                <div className='kyc_sub'>
                                    <h2>ALTERNATE ADDRESS</h2>
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>Address</h3>
                                        <i>:</i>
                                        {/* <span>N/A</span> */}
                                        <span>{(kycDetails?.address?.alternative_address_details?.address) ? kycDetails?.address?.alternative_address_details?.address : '--'}</span>
                                    </div>
                                    {/* <div className="d-flex justify-content-between box_card_data">
                                        <h3>Address 2</h3>
                                        <i>:</i>
                                        <span>N/A</span>
                                    </div> */}
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>City</h3>
                                        <i>:</i>
                                        <span>{(kycDetails?.address?.alternative_address_details?.city) ? kycDetails?.address?.alternative_address_details?.city : '--'}</span>
                                    </div>
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>State</h3>
                                        <i>:</i>
                                        <span>{(kycDetails?.address?.alternative_address_details?.state) ? kycDetails?.address?.alternative_address_details?.state : '--'}</span>
                                    </div>
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>Pincode</h3>
                                        <i>:</i>
                                        <span>{(kycDetails?.address?.alternative_address_details?.pincode) ? kycDetails?.address?.alternative_address_details?.pincode : '--'}</span>
                                    </div>
                                    {/* <div className="d-flex justify-content-between box_card_data">
                                        <h3>Country</h3>
                                        <i>:</i>
                                        <span>N/A</span>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* PARENT DETAILS */}
                    {
                        residentType === "student" ?
                        <div className='col-md-6 zindex9'>
                            <div className="table_main_tit pb-20"><h2>Parent Details</h2></div>
                            <div className='brd_box_card'>
                                <div className="box_head">
                                    <h2> <img src="/images/building.svg" className="pr-2" alt="" /> PARENT</h2>
                                </div>
                                <div className="box_contain details_tab">
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>Is Adhar Verified</h3>
                                        <i>:</i>
                                        {
                                            parentDetails?.is_aadhaar_verified ? (
                                                <span className='green_verify'>
                                                    <img src="/images/check-verified.svg" className="pr-1" alt="" />{' '}Verified
                                                </span>
                                            ) : (
                                                <span className='red_not_verify'>
                                                    <img src="/images/red_close.svg" className="pr-1" alt="" />{' '}Not Verified
                                                </span>
                                            )
                                        }
                                    </div>
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>Adhar number</h3>
                                        <i>:</i>
                                        <span>{parentDetails?.aadhaar_no ? parentDetails?.aadhaar_no : '--'}</span>
                                    </div>
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>Adhar document</h3>
                                        <i>:</i>
                                        {
                                            parentDetails?.aadhar_document_url ? (
                                                <span className='pdf_clr cursor-pointor'>
                                                    <a
                                                        href={parentDetails?.aadhar_document_url}
                                                        download
                                                        target='_blank'
                                                    ><img src="/images/book.svg" className="pr-1" alt="Book" />{' '}ID-Card.pdf</a>
                                                </span>
                                            ) : (
                                                <span className='pdf_clr'>--</span>
                                            )
                                        }
                                    </div>
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>Date of birth</h3>
                                        <i>:</i>
                                        {/* <span>{parentDetails?.dob ? dateFormat(parentDetails?.dob) : '--'}</span> */}
                                        <span>{parentDetails?.dob ? parentDetails?.dob : '--'}</span>
                                    </div>
                                    <div className="d-flex justify-content-between box_card_data">
                                        <h3>Name as per Adhar</h3>
                                        <i>:</i>
                                        <span>{parentDetails?.name_as_per_aadhar ? parentDetails?.name_as_per_aadhar : '--'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>
                <div className='details_bg'><img src="/images/bg_tab_img.png" alt="" /></div>
            </div>
        </>
    );
}

export default KycTab;